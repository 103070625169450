import { Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import Helper from "../../Helper";
import PlotAPI from "../../plotAPI";
import TeamInfo from "../../teamInfo";

interface Props {
    team: any;
    size?: number;
    fontSize?: string;
    showTooltip?: boolean;
    showName?: boolean;
}

const TeamAvatar: React.FC<Props> = (props: Props) => {
    const [uri, setUri] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (props.team) {
            PlotAPI.getTeamPic(props.team.subdomain).then((response) => {
                if (response) {
                    setUri(response);
                } else {
                    setUri(null);
                }
            });
        } else {
            setUri(null);
        }
    }, [props.team]);

    function getInitials(team: any) {
        if (!team) {
            return "";
        }

        if (team.initials && team.initials.length > 0) {
            return team.initials;
        }

        const fullName: string[] = team.team_name.toUpperCase().replace("THE ", "").split(" ");

        if (fullName.length > 1) {
            const firstPart = fullName.shift();
            const lastPart = fullName.pop();

            if (!firstPart || !lastPart) {
                return "";
            }

            return firstPart.charAt(0) + lastPart.charAt(0);
        }

        return fullName.shift()?.charAt(0) || "";
    }

    function bgLogic(team: TeamInfo) {
        if (uri) {
            return "#FFF";
        } else if (team.brand_color) {
            return team.brand_color;
        }

        return Helper.stringToColor(team.team_name);
    }

    return (
        <Tooltip title={props.team.team_name} disableHoverListener={!props.showTooltip}>
            <div className="flex flex-row items-center justify-center">
                <Avatar
                    src={uri}
                    sx={{
                        bgcolor: bgLogic(props.team),
                        fontSize: props.fontSize ? props.fontSize : "1rem",
                        width: props.size ? props.size : 40,
                        height: props.size ? props.size : 40,
                    }}
                >
                    {getInitials(props.team)}
                </Avatar>

                {props.showName && <span className="ml-1 text-xs">{props.team.team_name}</span>}
            </div>
        </Tooltip>
    );
};

export default TeamAvatar;
